import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PostList from "../../components/postList"

function HeroSection() {
    return (
        <div className="py-16">
            <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 mb-8 sm:mb-10">
                Books
            </h1>
            <p className="max-w-4xl text-lg sm:text-2xl sm:leading-10 font-medium">
                Design books is a central place for UI/UX designers, graphic designers,
                and creative people to find the best books on UX design and creativity.
                Whether you are a beginner or an experienced designer, these books help
                you make substantial progress in your design career.
            </p>
        </div>
    )
}

function BooksPage({ data, location }) {
    const posts = data.allMarkdownRemark.nodes

    return (
        <Layout location={location}>
            <Seo title="UX Choice books is a curated collection of top books on UI/UX design and creativity" description="Design books is a curated collection of best books about UX, UI design, and creativity for UX designers, product designers, graphic designers, and creative people." />
            <main className="">
                <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
                    <HeroSection />
                    <div className="mb-16">
                        <PostList posts={posts} />
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default BooksPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "books" } } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          date
          coverImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
